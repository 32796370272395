import React from "react"
import SEO from "../components/seo"
import "./awardees.css"
import logo from "../images/logo.png"

const DealerAwardees = () => {
  const awardees = [
    "Alex Anderson",
    "Madelyn Green",
    "Chloe Scharf",
    "Sydney Baker",
    "Avery Hardee",
    "Kali Siembor",
    "Carley Broekhuizen",
    "Ryan Hazelton",
    "Chase Vancoughnett-Lafleur  ",
    "Reagan Broekhuizen",
    "Jack Kelly",
    "Reese Vestal",
    "Barrett Chambers",
    "Jenna Krieg",
    "Rhea Warner",
    "Michael Ciolino",
    "Grayson Morgan",
    "Evan Windorski",
    "Abigail Decker",
    "Miranda Moulis",
    "Madilyn Winn",
  ];

  return (
    <div className="awardees">
      <img className="logo" src={logo} />
      <h2 className="awardee-header">We are pleased to announce the 2023 Brunswick Dealer Sons &amp; Daughters Scholarship Program Awardees</h2>
      <div className="awardee-grid">
        {awardees.map((awardee) => <span>{awardee}</span>)}
      </div>
    </div>
  );
};

export default DealerAwardees
